@import "variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "~toastify-js/src/toastify.css";

.form-page {
  width: 100%;
  max-width: 400px;
}

.profile-card {
  align-items: center;
  justify-content: center;
}

.profile-item {
  color: $secondary;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  .icon {
    font-size: 2rem;
    min-width: 32px;
  }
}

.appointment-content,
.session-content {
  position: relative;
  border: 1px solid lightgrey;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .item {
    font-size: clamp(1.25rem, 2vw, 1.5rem);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    .icon {
      color: $secondary;
    }
  }
}

.background {
  &-primary {
    background: $primary;
  }

  &-secondary {
    background: $secondary;
  }

  &-success {
    background: map-get($theme-colors, "success");
  }

  &-danger {
    background: map-get($theme-colors, "danger");
  }
}

.time-select-table {
  td {
    padding: 1rem 0rem;

    &:hover {
      cursor: pointer;
      background-color: $primary;
      color: white;
    }
  }

  .selected {
    background-color: $primary;
    color: white;
  }

  .disabled {
    background-color: #bdc6ce;
    color: #6c757d;

    &:hover {
      background-color: #bdc6ce;
      color: #6c757d;
      cursor: default;
    }
  }
}

.session-signature-item {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 300px;
}

.session-item-detail {
  margin-bottom: 3rem;
  font-size: clamp(1.25rem, 2vw, 1.5rem);

  .session-signature {
    width: 100%;
    max-width: 100px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 1rem;
  }

  .session-initial {
    width: 100%;
    max-width: 100px;
  }
}

.license-upload {
  height: 200px;
  border: 3px dashed black;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(black, 0.15);

  .label {
    color: grey;
  }

  &:focus {
    outline: 2px solid $primary;
    border: none;
  }
}

.table-wrapper {
  height: 50vh;
  overflow-y: scroll;
  border: 2px solid rgba(grey, 0.2);
  border-radius: 3px;
  padding: 5px;
}

.thumbnail {
  position: relative;
  width: 100%;
  max-width: 250px;
  height: 175px;
  margin: auto;
  padding: 0.5rem;
  border: 1px solid lightgrey;

  img {
    width: 100%;
    height: 100%;
  }

  .delete-icon {
    color: $secondary;
    background-color: $light;
    border-radius: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 2rem;
    cursor: pointer;
    transition: 250ms ease-in-out;

    &:hover {
      color: lighten($secondary, 25);
    }
  }
}

.canvas {
  border: 2px solid $primary;
}

.pointer {
  cursor: pointer;
}

.instructor-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: 55px;
  border: 2px solid $secondary;
  padding: 10px;
  height: 150px;
  overflow-y: auto;

  .item {
    align-self: center;
    color: $secondary;
    border: 2px solid black;
    padding: 5px;
    cursor: pointer;

    &.selected {
      color: white;
      background: $primary;
      border: 2px solid black;
      padding: 5px;
    }
  }
}

.add-time-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-time-item {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-bottom: 0.5rem;
  align-items: center;
}

.time-item {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.date-select {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 0.25rem;
}

.user-status {
  text-align: center;
  color: white;
  cursor: pointer;
  border: 1px solid #e5e7eb;
  vertical-align: middle !important;

  &.approved {
    background: $success;

    &:hover {
      background: darken($success, 15%);
    }
  }

  &.pending {
    color: $dark;
    background: $warning;

    &:hover {
      background: darken($warning, 15%);
    }
  }

  &.partially-paid {
    color: $dark;
    background: $warning;

    &:hover {
      background: darken($warning, 15%);
    }
  }
}

.booking-step-wrapper {
  border: 1px solid lightgrey;
  padding: 1rem;

  @media (min-width: 1000px) {
    height: 550px;
  }
}

.registration-steps-wrapper {
  border: 1px solid lightgrey;
  padding: 1rem;
  height: 575px;
  overflow: auto;

  @media (max-width: 768px) {
    height: 100%;
  }
}

.register-get-started-section {
  margin-top: 1.5rem;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 1rem;
}

.get-started-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  font-size: 1.25rem;
  color: $secondary;
  transition: 350ms ease-in-out;

  &:hover {
    background-color: $secondary;
    color: white;
    cursor: pointer;
  }
}

.register-package-selection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;

  .card-header {
    font-weight: 500;

    .package-price {
      text-decoration: underline;
    }
  }

  .card {
    transition: 350ms ease-in-out;
    border: 1px solid lightgrey;

    &:hover,
    &.active {
      background-color: $primary;
      color: white;
      cursor: pointer;
    }
  }
}

.steps-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  background-color: lightgrey;
  margin-bottom: 1rem;
  padding: 1rem;

  button {
    background-color: white;
    border: none;
    padding: 1rem;
    color: $secondary;
    cursor: pointer;
    transition: 350ms ease-in-out;

    &.active, &:hover {
      background-color: $secondary;
      color: white;
    }

    &:disabled {
      cursor: auto;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;

    button {
      width: 100%;
    }
  }
}

.final-step-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
  }

  button {
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.divider {
  width: 100%;
  height: 2px;
  background-color: $light;
  margin: 1rem 0;

  &.light {
    background-color: $light;
  }

  &.dark {
    background-color: lightgrey;
  }
}

.table-loading-area {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(255, 255, 255, 0.75);
}

.user-table-filter {
  border: 1px solid lightgrey;
  padding: 1rem;
  margin-bottom: 2rem;

  &-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    width: 100%;
  }
}

.user-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.user-list-pagination {
  @media (max-width: 480px) {
    display: none;
  }
}

.student-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  border: 1px solid lightgrey;
  padding: 2rem;

  h4 {
    line-height: 1.75;
    margin-bottom: 3rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    justify-content: flex-start;
    height: auto;

    h4 {
      margin-bottom: 1.5rem;
    }
  }

  &-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 1.5rem;
    gap: 1rem;

    @media (max-width: 480px) {
      justify-content: flex-start;
    }
  }
}

.session-print-area {
  @media print {
    padding: 3rem 2rem;
  }
}

.file-upload-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: #d9d5d5;
  border-style: dashed;
  background-color: #fafafa;
  color: #a6a6a6;
  outline: none;
  transition: border 0.24s ease-in-out;

  &:focus {
    border-color: $secondary;
  }
}

.disabled-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 100;
  top: 0;
  left: 0;
}

.form-checkbox {
  input {
    width: 25px;
    height: 25px;
  }
}

.payroll-card {
  color: white;
  padding: 1rem;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;

  h2, h3, h5 {
    display: flex;
    align-items: center;
    gap: 0.5rem; 
  }

  .amount {
    font-weight: bold;

    &::after {
      content: " hour(s)";
    }
  }

  .instructor-label {
    position: absolute;
    top: 2px;
    right: 6px;
  }

  .number-of-sessions::after {
    content: " appointment(s)";
  }

  .instructor-name, .number-of-sessions, .date-range, .hour-stats {
    font-weight: 200;
  }
}

.payroll-results-wrapper {
  .title {
    display: none;
  }

  .payroll-table-wrapper {
    height: 500px; 
    overflow: auto;
  }

  @media print {
    .title {
      display: block;
    }
    padding: 3rem 2rem;

    .hide-on-print {
      display: none !important;
    }

    .payroll-table-wrapper {
      margin-top: 3rem;
      height: auto;
    }
  }
}